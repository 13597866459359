import React from "react"
import { Link } from "gatsby"

import "src/components/Blog/PagePagination/_styles.scss";

function BlogBanner({data}) {
  const { next, previous } = data;
  let pagClass = (next && previous) ? 'half' : 'full';
  return (
    <div className={`page-pagination`}>
      <div className={`page-pagination-wrapper`}>
        {
          previous &&
          <Link to={`/blog/${previous.slug}`} className={`pagination-btn page-previous ${pagClass}`}>
            <div className="arrow left"></div>
            <h3 className="title">{previous.title}</h3>
            <img className="pagination-btn-image" src={previous.carousel.carousel[0].file.url} alt="Mada - App" />
          </Link>
        }
        {
          next &&
            <Link to={`/blog/${next.slug}`} className={`pagination-btn page-next ${pagClass}`}>
              <img className="pagination-btn-image" src={next.carousel.carousel[0].file.url} alt="Mada - App" />
              <h3 className="title">{next.title}</h3>
              <div className="arrow right"></div>
            </Link>
        }
      </div>
    </div>
  )
}

export default BlogBanner
