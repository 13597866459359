import React from "react";
import { graphql } from "gatsby";
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import Layout from "src/components/Layout/Layout";
import SEO from "src/components/Seo/Seo";

import MadaContent from "src/components/Common/MadaContent/MadaContent"
import Slider from "src/components/Slider/Slider";
import BlogBanner from "src/components/Blog/BlogBanner/BlogBanner";
import PagePagination from "src/components/Blog/PagePagination/PagePagination";

import "src/templates/BlogPage/_styles.scss";

const BlogPage = ({ data, pageContext }) => {
  const { title, body, pompadour, date, carousel, category, slug, metaDescription, metaTitle } = data.contentfulBlogPost;
  return (
    <Layout>
      <SEO title={metaTitle} description={metaDescription} image={carousel.carousel[0].file.url} />
      <Slider data={carousel.carousel} />
      <div className="blogpost">
        <div className="social-networks">
          <TwitterShareButton url={pageContext.site.siteMetadata.url+'/blog/'+slug} title={title} className="icon-box">
              <svg className="icon" width="20" height="16" viewBox="0 0 20 16">
                <path fill-rule="nonzero" d="M19.2 1.894a8.047 8.047 0 0 1-2.268.638A3.998 3.998 0 0 0 18.664.3c-.761.466-1.601.794-2.496.978A3.9 3.9 0 0 0 13.292 0C11.113 0 9.36 1.814 9.36 4.038c0 .32.026.628.091.92C6.178 4.796 3.282 3.187 1.337.737a4.144 4.144 0 0 0-.539 2.04c0 1.399.702 2.638 1.748 3.356a3.813 3.813 0 0 1-1.778-.498v.045c0 1.962 1.364 3.591 3.154 3.967-.32.09-.67.132-1.032.132-.252 0-.507-.014-.746-.068.51 1.598 1.958 2.774 3.678 2.812a7.784 7.784 0 0 1-4.88 1.722A7.19 7.19 0 0 1 0 14.188 10.881 10.881 0 0 0 6.038 16c7.244 0 11.204-6.154 11.204-11.488 0-.178-.006-.35-.015-.522A7.967 7.967 0 0 0 19.2 1.894z"/>
              </svg>
          </TwitterShareButton>
          <FacebookShareButton url={pageContext.site.siteMetadata.url+'/blog/'+slug} title={title} className="icon-box">
            <svg className="icon" width="9" height="16" viewBox="0 0 9 16">
                <path fill="" fill-rule="nonzero" d="M8.623.003L6.473 0C4.06 0 2.5 1.546 2.5 3.938v1.815H.338A.332.332 0 0 0 0 6.08v2.63c0 .18.151.326.338.326h2.16v6.638c0 .18.152.326.339.326h2.819a.332.332 0 0 0 .338-.326V9.036H8.52a.332.332 0 0 0 .338-.326l.001-2.63a.321.321 0 0 0-.099-.231.344.344 0 0 0-.239-.096H5.994V4.214c0-.74.182-1.115 1.18-1.115h1.448a.332.332 0 0 0 .338-.327V.33a.332.332 0 0 0-.337-.327z"/>
            </svg>
          </FacebookShareButton>
        </div>
        <div className="category">{category}</div>
        <div className="blogpost-wrapper">
          <h1>{title}</h1>
          <div className="date">{date}</div>
          <div className="pompadour">
            {
              pompadour.pompadour.split('\n').map((item, i) => {
                return <p key={i}>{item}</p>;
              })
            }
          </div>
          <div className="body-text">
            <MadaContent>
              {body.json}
            </MadaContent>
          </div>
        </div>
      </div>
      <BlogBanner />
      <PagePagination data={{next: pageContext.next, previous: pageContext.previous}}/>
    </Layout>
  );
};
export default BlogPage;

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      carousel {
        carousel {
          file {
            url
          }
        }
      }
      title
      description
      metaTitle
      metaDescription
      slug
      date(formatString: "D MMM YYYY")
      body {
        json
      }
      category
      pompadour {
        pompadour
      }
    }
  }
`;
